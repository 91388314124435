import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { fonts, colors } from '@marvelapp/theme';
import { Heading, Text } from '@marvelapp/ui';

import Page from '../components/Page';
import PageMeta from '../components/PageMeta';

export const query = graphql`
  query($fileUri: String!) {
    gherkin(uri: { eq: $fileUri }) {
      comments {
        text
      }
      feature {
        name
      }
    }
  }
`;

export default function FeaturePage({
  data: { gherkin },
  location,
  pageContext: { formattedCode },
}) {
  const { comments, feature } = gherkin;
  const pageTitle = getTextFromComment(comments, '# Title:');
  const pageDescription = getTextFromComment(comments, '# Description:');
  return (
    <Page location={location}>
      <PageMeta title={pageTitle || feature.name} />
      <Heading as="h1" fontSize={[6, 7]} marginTop={[6, 7, 104]}>
        {pageTitle || feature.name}
      </Heading>
      {pageDescription && (
        <Text fontSize={[18, 22]} lineHeight={1.7}>
          {pageDescription}
        </Text>
      )}
      <HiglightedCode>
        <pre className="language-markup">
          <code
            className="language-gherkin"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: formattedCode }}
          />
        </pre>
      </HiglightedCode>
    </Page>
  );
}

function getTextFromComment(comments, commentTag) {
  const titleComment = comments.find((comment) =>
    comment.text.startsWith(commentTag)
  );
  return titleComment ? titleComment.text.replace(commentTag, '').trim() : '';
}

const HiglightedCode = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  background-color: ${colors.snow};
  border: 1px solid ${colors.smoke};

  code {
    padding: 0;
    border-left: none !important;
    box-shadow: none !important;
    background: none !important;
  }

  pre {
    margin: 0;
    padding: 12px 16px;
    box-shadow: none !important;
    background: none !important;
    font-family: ${fonts[1]} !important;
    font-size: 13px !important;

    &:before,
    &:after {
      display: none !important;
    }
  }
`;
